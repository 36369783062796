<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="companies3042"
		tooltipMsg="manualforcompan"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'AcompanyName', ascending: 0 }"
		modelId="AcompanyId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #CountryId="list">
			<country-flag :countryId="+list.row.CountryId"></country-flag>
		</template>

		<template #AcompanyIsUsed="list">
			<boolean-display :boolValue="!!list.row.AcompanyIsUsed"></boolean-display>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './accounting-company.table-data';

export default {
	name: 'AccountingCompanyTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'AccountingCompanyListItem',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(AcompanyId = 0) {
			this.$router.push({
				name: links.accountingCompanyDetailName,
				params: { AcompanyId },
			});
		},
	},
};
</script>