export const tableColumns = [
	{
		model: 'AcompanyName',
		i18n: 'companyname3043',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AcompanyStreet',
		i18n: 'street3044',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AcompanyCity',
		i18n: 'city2573',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AcompanyIco',
		i18n: 'companyid3045',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
	{
		model: 'AcompanyIsUsed',
		i18n: 'active',
		sortable: true,
		filter: 'boolean',
	},
];
